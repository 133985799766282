<template>
  <div>
    <b-card class="m-5">
      <template #header>
        <h1>{{ $t('contact.label') }}</h1>
      </template>
      <b-card-text>
        <div v-if="language === 'de'">
          <p>
            <strong>HPC-Support</strong>
          </p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>
        </div>
        <div v-else>
          <p>
            <strong>HPC-Support</strong>
          </p>

          <p>
            Email: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Contact',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}
h2 {
  font-size: 1.15rem;
  font-weight: bold;
}
h3 {
  font-size: 1.05rem;
  font-weight: bold;
}
h4 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
